import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Exklusive Aktionen und Rabatte"
const text =
  "Wir freuen uns mit Ihnen über Ihren neuen Liebling und um dies gemeinsam zu feiern, profitieren Sie als registriertes Mitglied des WelpenOrganizer von exklusiven Aktionen und Rabatten."

const Aktionen = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader page={"aktionen"} headline={headline} text={text} />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Aktionen
